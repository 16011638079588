<!-- 
  This is a wrapper around the PrimeVue Image component. It allows the user to preview an image.
 -->
<template>
  <label v-if="props.label" class="text-sm mb-2">{{ props.label }}</label>
  <div class="relative app-image h-full" :class="class">
    <Image
      :preview="props.preview"
      class="app-image h-full"
      style="
        justify-content: center;
        align-items: center;
        display: flex;
        user-select: none;
      "
      :src="computedSrc"
    >
      <template #image v-slot="slotProps">
        <img
          :src="computedSrc"
          :class="['app-image', 'h-full', imageClass]"
          :width="props.width"
          :height="props.height || 'auto'"
          data-pc-section="image"
        />
      </template>
    </Image>
    <div v-if="deletable" class="absolute z-5 deletable">
      <AppIcon
        @click="handleImageDelete($event)"
        color="danger"
        name="i-heroicons:x-mark"
        size="30"
      />
    </div>
    <div v-if="selectable" class="absolute z-5 selectable">
      <div
        style="width: 30px; height: 30px; user-select: none"
        :class="{ 'select-image-checkbox-selected': selected }"
        class="select-image-checkbox bg-red relative"
        @click="
          () => {
            selected = !selected;
            emits('selected');
          }
        "
      >
        <div
          v-if="selected"
          class="absolute"
          style="transform: translate(-2px, -2px)"
        >
          <AppIcon name="i-heroicons:check-circle-16-solid" size="30" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import img1 from '~/assets/images/noimg.svg';

const selected = defineModel<boolean>('selected', {
  required: false,
});

const computedSrc = computed(() => {
  return props.src || img1;
});

const props = defineProps({
  width: Number,
  height: Number,
  label: String,
  class: String,
  src: String,
  preview: {
    type: Boolean,
    default: false,
  },
  deletable: {
    type: Boolean,
    default: false,
  },
  selectable: {
    type: Boolean,
    default: false,
  },
  imageClass: {
    type: [String, Array, Object] as PropType<
      string | string[] | Record<string, boolean>
    >,
    default: '',
  },
});

const emits = defineEmits(['imageDeleted', 'selected']);

const handleImageDelete = (event: Event) => {
  event.stopPropagation();
  emits('imageDeleted');
};
</script>

<style scoped>
:deep(.first-button) {
  top: 0;
  right: 0;
  transform: translate(25%, -35%);
}
:deep(.second-button) {
  top: 0;
  left: 0;
  transform: translate(-25%, -35%);
}
:deep(.p-checkbox) {
  width: 2.4rem;
  height: 2.4rem;
}
:deep(.p-checkbox-box) {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
}

:deep(.deletable) {
  top: 0;
  right: 0;
  transform: translate(-25%, 25%);
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

:deep(.selectable) {
  top: 0;
  left: 0;
  transform: translate(25%, 25%);
}
</style>
